import React, { useEffect, useState } from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { css } from "styled-components/macro"; //eslint-disable-line

import tw from "twin.macro"; //eslint-disable-line

import 'bootstrap/dist/css/bootstrap.min.css';
import "../../Home.css" ; 
import { useLocation, useNavigate } from "react-router-dom";
import logo from "assets/images/logo_mini.png";

import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";
import styled from "styled-components";
import Header, { NavLink, NavLinks, PrimaryLink, LogoLink, NavToggle, DesktopNavLinks } from "components/headers/light.js";

import { useSelector, useDispatch } from 'react-redux'
import { changeStoreLang } from "langSlice";

const StyledHeader = styled(Header)`
  ${tw`pt-8 max-w-none`}
  ${DesktopNavLinks} ${NavLink}, ${LogoLink} {
    ${tw`text-gray-100 hover:border-gray-300 hover:text-gray-300`}
  }
  ${NavToggle}.closed {
    ${tw`text-gray-100 hover:text-primary-500`}
  }
`;
export default function App(props) {
  // If you want to disable the animation just use the disabled `prop` like below on your page's component
  // return <AnimationRevealPage disabled>xxxxxxxxxx</AnimationRevealPage>;

  let navigate = useNavigate();
  
  const lang = useSelector((state) => state.lang.value) ;
  const dispatch = useDispatch() ;

  const navLinks = [
    <NavLinks key={1}>
      
       <MenuIcon tw="w-6 h-6"  onClick={()=> {props.onOpenSideBar(true) ;}}/> 
      <NavLink onClick={()=> {navigate("/");}}>
        Home
      </NavLink>
      <NavLink  onClick={()=> {navigate("/services");}}>
        Services
      </NavLink>
      <NavLink  onClick={()=> {navigate("/abonnements");}}>
        Abonnements
      </NavLink>
      <NavLink href="#" onClick={()=> {navigate("/partenaires");}}>
      Partenaires
      </NavLink>
      <NavLink href="#" onClick={()=> {navigate("/contactUs");}}>
      Contacter nous
      </NavLink>
    </NavLinks>,
    <NavLinks key={2}>
    </NavLinks>
  ];
  const useCurrentPath = () => {
    const location = useLocation()
    // const [{ route }] = matchRoutes(routes, location)
  
    return location.pathname ;
  }
  const currentPath = useCurrentPath()  ; 
  useEffect(()=>{
    console.log("currentPath") ; 
    console.log(currentPath) ; 
  } , [currentPath, dispatch]) ;
  
  
  const changeLang = async (newLang) => {
    if(newLang === "fr") {
      await dispatch(changeStoreLang("fr")) ; 
      navigate(currentPath.replace("/de" , "/fr")) ;  
    }else {
      await dispatch(changeStoreLang("en")) ; 
      navigate(currentPath.replace("/fr" , "/de")) ;  
    }
    
  }

  
  function isMobileDevice() {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      window.navigator.userAgent
    );
  }
  

  return (
    <>
      <div className="header_smart_solution" id="header_smart " style={{position : "fixed" , width : "100%" , top : "0" , left : "0%" , paddingLeft : "5%" , 
      paddingRight : "5%" , height : "70px" , zIndex : "10000" }}>
        {/* <StyledHeader links={navLinks} /> */}
      <div className="d-flex justify-content-between align-items-center w-100"  style={{height : "100%"}}>
       <MenuIcon tw="w-6 h-6" style={{cursor : "pointer"}}  onClick={()=> {props.onOpenSideBar(true) ;}}/> 
        <div style={{fontWeight : "bold" ,fontSize : "16px" , cursor : "pointer"}} onClick={()=> {
          if(lang == "en") {
            navigate("/de") ;
          }else {
            navigate("/fr") ;
          }
        }}>
        <img alt= "" src={logo} style={ isMobileDevice() ? {height : "auto" , width : "150px"} :  {height : "69px" , width : "auto"}} />
        </div>
        <div className="row h-100">
          <div className="col-4  d-flex justiy-content-center align-items-center" 
              style={{color : lang === "fr" ? "black" : "grey" , fontSize : "11px" , cursor : "pointer" , fontWeight : lang === "fr" ? "bold" : ""}}
              onClick={()=>{changeLang("fr")}}
              >
                FR
          </div>
          <div className="col-4  d-flex justiy-content-center align-items-center" 
              style={{color : lang === "en" ? "black" : "grey" , fontSize : "11px" , cursor : "pointer" , fontWeight : lang === "en" ? "bold" : ""}}
              onClick={()=>{changeLang("de")}}
              >
                DE
          </div>
          {/* <div className="col-4  d-flex justiy-content-center align-items-center" 
              style={{color : lang == "ar" ? "black" : "grey" , fontSize : "11px" , cursor : "pointer" , fontWeight : lang == "ar" ? "bold" : ""}}
              onClick={()=>{changeLang("ar")}}
              >
                AR
          </div> */}
          
        </div>

      </div>
      </div>
    </>
  );
}
