import axios from 'axios';

export const BASE_URL = 'https://monsieurweb3.com/ws/public/api/';
export const BASE_URL_Image = 'https://monsieurweb3.com/ws/public/';

export const authApi = axios.create({
  baseURL: BASE_URL,
});

authApi.defaults.headers.common['Content-Type'] = 'application/json';
