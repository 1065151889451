/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// react-router-dom components
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import {  toast } from 'react-toastify';
import { useMutation } from "@tanstack/react-query";
import { signin } from "services/authService";

// Images

function Basic() {

  const { mutate, isLoading } = useMutation(
    (userData) => signin(userData),
    {
      async onSuccess(data) {
        console.log(data);
        
        if(data.result) {
          notify_success("valid") ; 
          localStorage.setItem("token" , data.token ) ; 
          localStorage.setItem("email" , data.email ) ; 
          localStorage.setItem("name" , data.name ) ; 
          localStorage.setItem("id" , data.id ) ; 
          localStorage.setItem("role" , data.role ) ; 
          if(data.role === "admin") {
            navigate('/admin');
          }
          else{
            navigate('/');
          }
        }else { 
          notify("error")
        }
      },
      onError(error) {
        notify("error")
        if (Array.isArray((error).response.data.error)) {
          (error).response.data.error.forEach((el) =>
          console.log(el.message) )
        } else {
          console.log((error).response.data.message)
        }
      },
    }
  );
  const [rememberMe, setRememberMe] = useState(false);
  const [email , setEmail] = useState('') ;
  const [password , setPassword] = useState('') ;

  const navigate = useNavigate();
  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  
  const Login = () => {
    var user = {
      email ,
      password ,
    } ; 
    console.log(user) ;

    var valid = true ;

    if(!email || email === "" || !String(email).toLowerCase().match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )) {
      notify("Enter your email please !") ;
      valid = false ;
    }
    else if(!password || password === "") {
      notify("Enter your password please !") ;
      valid = false ;
    }
    
    if(valid) {
      notify_success("valid form") ;
      mutate(user) ; 
      // navigate("/admin");

    }
  }


  const notify = (message) => {
    
    toast.error(message, {
      position: "bottom-right",
      autoClose: 3000 , 
      fontSize : "12px" 
      });
  }

  const notify_success = (message) => {
    
    toast.success(message, {
      position: "bottom-right",
      autoClose: 3000
      });
  }

  useEffect(()=> {
    let token = localStorage.getItem("token") ; 
    let role = localStorage.getItem("role") ; 
    if(token != null && token !== "") {
      if(role === "admin") {
        navigate("/admin") ;
      }
      else  {
        navigate('/');
      }
    }
  } , []) ;



  return (
    <div className="d-flex justify-content-center align-items-center w-100" style={{background : "#fdfbf7" , minHeight : "100vh"}}>
      <Card style={{minWidth : "300px" , width : "40vh"}}>
        <MDBox
          variant="gradient"
          bgColor="dark"
          borderRadius="lg"
          coloredShadow="dark"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Login
          </MDTypography>
          {/* <Grid container spacing={3} justifyContent="center" sx={{ mt: 1, mb: 2 }}>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <FacebookIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GitHubIcon color="inherit" />
              </MDTypography>
            </Grid>
            <Grid item xs={2}>
              <MDTypography component={MuiLink} href="#" variant="body1" color="white">
                <GoogleIcon color="inherit" />
              </MDTypography>
            </Grid>
          </Grid> */}
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2}>
              <MDInput type="email" label="Email" fullWidth onChange={e => setEmail(e.currentTarget.value)}/>
            </MDBox>
            <MDBox mb={2}>
              <MDInput type="password" label="Mot de passe" fullWidth onChange={e => setPassword(e.currentTarget.value)}/>
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Switch checked={rememberMe} onChange={handleSetRememberMe} />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                onClick={handleSetRememberMe}
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;Souviens-toi de moi
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton variant="gradient" color="dark" fullWidth onClick={()=> {
                // navigate("/admin") ;
                Login() ;
              }}>
                se connecter
              </MDButton>
            </MDBox>
            <MDBox mt={3} mb={1} textAlign="center">
              {/* <MDTypography variant="button" color="text">
                <MDTypography
                  component={Link}
                  to="/"
                  variant="button"
                  color="dark"
                  fontWeight="medium"
                  textGradient
                >
                   Mot de passe oublié ?
                </MDTypography>
              </MDTypography> */}
            </MDBox>
          </MDBox>
        </MDBox>
      </Card>
    </div>
  );
}

export default Basic;
